<template>
  <v-card color="basil">
    <ik-data-table
      ref="statisticTable"
      :entity-name="$t('product_out_statistics')"
      :model="model"
      :use-action="false"
      :use-date-filter="true"
      :use-default-action="false"
      :use-select="false"
      icon="mdi-calculator-variant"
    >
      <template #filterLeft>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          offset-y
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dates"
              :label="$t('selectDate')"
              append-icon="mdi-close"
              class="ml-auto"
              hide-details
              prepend-icon="mdi-calendar"
              readonly
              single-line
              style="max-width: 250px;"
              v-on="on"
              @click:append="dates=[]"
            />
          </template>
          <v-date-picker
            v-model="dates"
            locale="de"
            range
          >
            <v-spacer/>
            <v-btn
              color="primary"
              text
              @click="menu = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="$refs.menu.save(dates)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </template>
    </ik-data-table>
  </v-card>
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import DishDeliveryStatistics from '../../../model/export/DishDeliveryStatistics'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: DishDeliveryStatistics,
        dates: [],
        menu: false,
      })
    },
    computed: {
      filter () {
        if (Date.parse(this.dates[0]) > Date.parse(this.dates[1])) {
          [this.dates[0], this.dates[1]] = [this.dates[1], this.dates[0]]
        }
        return {
          start: this.dates[0],
          end: this.dates[1],
        }
      },
    },
    mounted () {
    },
    methods: {},
  }
</script>
